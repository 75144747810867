<template>
  <div class="container mx-auto px-4">
    <div class="flex">
      <div class="content min-h-screen w-100 min-h-fulls py-5 px-10 w-full">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="scss">
.content{
  min-height: 100vh;
}
</style>
