import { createApp } from 'vue';
import App from '@/App';
import Store from '@/store';
import Routes from "@/routes";
import '@/assets/scss/main.scss';

const app = createApp(App);

app.use(Routes);
app.use(Store);
app.mount('#app');

app.config.errorHandler = (error, instance, info) => {
  console.log(error);
  console.log(instance);
  console.info(info)
}
