import {createRouter, createWebHistory} from "vue-router";

const routes = [
  { path: '/', name: 'home', component: () => import('@/components/views/phrases/Index.vue') },
  { path: '/me', name: 'me', component: () => import('@/components/views/Me') },
  { path: '/resume', name: 'resume', component: () => import('@/components/views/resume/Index') },
  { path: '/articles', name: 'articles', component: () => import('@/components/views/articles/Index') },
  { path: '/codes', name: 'codes', component: () => import('@/components/views/codes/Index') },
  { path: '/cases', name: 'cases', component: () => import('@/components/views/cases/Index') },
  { path: '/contact', name: 'contact', component: () => import('@/components/views/contact/Index') },
  { path: '/tools', name: 'tools', component: () => import('@/components/views/tools/Index') },
  { path: '/links', name: 'links', component: () => import('@/components/views/links/Index') },

  { path: '/exchange', name: 'exchange', component: () => import('@/components/views/exchange/Index') },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
